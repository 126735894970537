import React from "react";

const Header = ({ headerRef }) => {
  return (
    <header
      id="header"
      ref={headerRef}
      className="text-white bg-warning w-100 p-2 d-flex"
    >
      <h1 class="header">AWS Cloud Practitioner Essentials 補足資料</h1>
    </header>
  );
};

export default Header;
