import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>AWS Cloud Practitioner Essentials へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/aws-cloud-practitioner-essentials/" target="blank">AWS Cloud Practitioner Essentials について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>Webex</h5>
        <p>トレーニング期間中は配信環境としてWebexを利用します。<br/>配信の他、「挙手」等にも利用します。<br/>ログインする際には実名で（漢字・ローマ字問いません）ご登録をお願いします。<br/><a href="https://a.co/7SeNx7p " target="blank">Webex の使い方 (PDF)</a></p>
        <hr/>
        <h5>Webex Chat / QA</h5>
        <p>
          トレーニング期間中は<br/>
          =====<br/>
          ・インストラクター～参加者<br/>
          ・参加者～参加者<br/>
          =====<br/>
          のテキストコミュニケーションに<b>"Webex Chat"</b>を利用します。<br/>
          モジュールの振り返りのアウトプットにも利用します。QA は開催回によりますがチャット or QA のいずれかで対応致します。
          <div class="blackboard-box">
            <p><b><span class="warn">各種機能のご説明</span></b></p>
            <p>基本、朝のオリエンテーション時にご案内致します。</p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
        </p>
        <hr/>
        <h5>テキストのダウンロード</h5>
        <p>
          トレーニングで利用するテキストは、Gilmore Bookshelf という電子テキスト配布システムで参照します。<br/>
          下記URLから利用手順をダウンロードして、テキストの引き換えを行ってください。<br/>
          <div class="blackboard-box">
            <p><b><span class="warn">事前案内でテキストをダウンロード済みの方は、こちらの手順は不要です</span></b></p>
            <div class="chalk1"></div>
            <div class="chalk2"></div>
          </div>
          <a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">テキストの利用手順 (PDF)</a>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          特になし
        </p>
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ（当コースではなし）</th>
          </tr>
          <tr>
            <td>1</td>
            <td>アマゾン　ウェブ　サービスの紹介</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>クラウドでのコンピューティング</td>
            <td></td>
          </tr>
          <tr>
            <td>3</td>
            <td>グローバルインフラストラクチャと信頼性</td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>ネットワーク</td>
            <td></td>
          </tr>
          <tr>
            <td>5</td>
            <td>ストレージとデータベース</td>
            <td></td>
          </tr>
          <tr>
            <td>6</td>
            <td>セキュリティ</td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td>モニタリングと分析</td>
            <td></td>
          </tr>
          <tr>
            <td>8</td>
            <td>料金とサポート</td>
            <td></td>
          </tr>
          <tr>
            <td>9</td>
            <td>移行とイノベーション</td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール1（アマゾン　ウェブ　サービスの紹介）</h5>
        ・<a href="https://aws.amazon.com/jp/blogs/news/prime-day-2021-two-chart-topping-days/" target="blank">プライムデー 2021 – トップチャートの 2 日間</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Amazon が AWS を利用してプライムデーのリクエストをどれくらい捌いているのかの値が出ているブログ記事<br/>
        <hr/>
        <h5>モジュール2（クラウドでのコンピューティング）</h5>
        ・<a href="https://aws.amazon.com/jp/ec2/instance-types/" target="blank">Amazon EC2 インスタンスタイプ</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/C2-07.pdf#page=38" target="blank">Amazon EC2 インスタンスタイプの選び方ガイド</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- インスタンスタイプ選びのポイント（まずは「M」を基準に）<br/>
        ・<a href="https://aws.amazon.com/jp/ec2/pricing/on-demand/" target="blank">Amazon EC2 オンデマンド料金</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/CUS-50_AWS_Summit_Online_2021_MISUMI_Group.pdf" target="blank">＜事例＞ミスミが仕掛ける！製造業の労働生産性改革 meviy（メヴィー）　〜システム開発加速の鍵はAWS〜</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS Auto Scaling を活用した事例の引用<br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/CUS-11_AWS_Summit_Online_2021_MEDIA_DO.pdf" target="blank">＜事例＞メディアドゥ新プラットフォーム出版ERPとブロックチェーン基盤のご紹介</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/mainichi-newspapers/" target="blank">＜事例＞AWS 導入事例： 株式会社 毎日新聞社</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-51_AWS_Summit_Online_2020_SonyBank.pdf" target="blank">＜事例＞ソニー銀行のクラウドジャーニー　〜AWSの利用開始から勘定系移行への道のり〜</a>
        <hr/>
        <h5>モジュール3（グローバルインフラストラクチャと信頼性）</h5>
        ・<a href="https://aws.amazon.com/jp/about-aws/global-infrastructure/" target="blank">グローバルインフラストラクチャ</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/CUS-79_AWS_Summit_Online_2020_QUICK-Corp.pdf" target="blank">＜事例＞金融エンタープライズのミッション　クリティカルシステム移行時の勘所</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- モジュール3の内容を押さえて頂く上でポイントとなる資料<br/>
        <hr/>
        <h5>モジュール4（ネットワーク）</h5>
        none
        <hr/>
        <h5>モジュール5（ストレージとデータベース）</h5>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/tv-tokyo/" target="blank">＜事例＞ 株式会社テレビ東京</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Amazon S3 Glacier をご活用頂きコストを抑止された事例<br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/tech-interview-finatext-1/" target="blank">Docker をフル活用して金融業界のシビアなパフォーマンス、セキュリティ要件に対応している株式会社Finatext石橋さんに、難しいポイントやアーキテクチャ上の工夫を伺いました – 前編</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/case-studies/sbi-sumishin-net-bank/" target="blank">＜事例＞ AWS 導入事例：住信SBIネット銀行株式会社</a><br/>
        <hr/>
        <h5>モジュール6（セキュリティ）</h5>
        ・<a href="https://aws.amazon.com/jp/compliance/customer-center/" target="blank">カスタマーコンプライアンスセンター</a>
        <hr/>
        <h5>モジュール7（モニタリングと分析）</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20190326_AWS-BlackBelt_CloudWatch.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon CloudWatch</a><br/>
        <hr/>
        <h5>モジュール8（料金とサポート）</h5>
        ・<a href="https://aws.amazon.com/jp/ec2/pricing/" target="blank">Amazon EC2 の料金</a><br/>
        ・<a href="https://aws.amazon.com/jp/s3/pricing/" target="blank">Amazon S3 の料金</a><br/>
        ・<a href="https://calculator.aws/#/addService" target="blank">AWS 料金見積りツール</a>
        <hr/>
        <h5>モジュール9（移行とイノベーション）</h5>
        ・<a href="https://aws.amazon.com/jp/professional-services/CAF/" target="blank">AWS クラウド導入フレームワーク (AWS CAF)</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/H2-04.pdf" target="blank">＜事例＞ネクソンの多彩なゲームコンテンツポートフォリオを支える基盤と運用の最適化について</a><br/>
        ・<a href="https://aws.amazon.com/jp/architecture/well-architected/" target="blank">AWS Well-Architected</a>
        <hr/>
        <h5>その他（順不同）</h5>
        ----- 【トレーニング情報】<br/>
        ・<a href="https://aws.amazon.com/jp/training/classroom/" target="blank">AWS クラスルームトレーニングの概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202203/way-to-choose-training/" target="blank">AWS 認定インストラクターによる有償トレーニングコースの選び方</a><br/>
        ----- 【資格関連情報】<br/>
        ・<a href="https://aws.amazon.com/jp/certification/" target="blank"> AWS 認定</a><br/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから730日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a>
      </div>
    </main>
  );
};

export default MainContents;
